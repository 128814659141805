@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: black;
    font-family: centra-book, Helvetica, Arial, sans-serif;
}

.showcase-bg {
    background-image: url("../public/red_fractal.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-size: cover;
    background-attachment: fixed;
    background-color: black;
}


.article > h2,
.article > p,
.article > code {
    @apply max-w-article m-auto
}

.article > h2 {
    @apply text-2xl mt-8;
}

.article > p {
    @apply my-2;
}

.article > code {
    @apply block bg-black text-white p-2 rounded my-4 font-mono;
}

.article > p > code {
    @apply bg-black text-white py-0.5 px-2 rounded font-mono;
}

.article > p > a {
    @apply underline
}

.text-decoration-inherit {
    text-decoration: inherit;
}